import React, { useState, useEffect } from "react";
import Pagination from "@mui/material/Pagination";
import Slider from "@mui/material/Slider";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { clearErrors, getProducts } from "../../actions/productAction";
import Loader from "../Layouts/Loader";
import Product from "./Product";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import StarIcon from "@mui/icons-material/Star";
import { categories } from "../../utils/constants";
import MetaData from "../Layouts/MetaData";
import {
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  Grid,
  TextField, // Import TextField for search input
} from "@material-ui/core";

const Products = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const location = useLocation();

  const [price, setPrice] = useState([0, 500]);
  const [category, setCategory] = useState(
    location.search ? location.search.split("=")[1] : ""
  );
  const [ratings, setRatings] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState("");

  const [categoryToggle, setCategoryToggle] = useState(false);
  const [ratingsToggle, setRatingsToggle] = useState(false);

  const {
    products,
    loading,
    error,
    productsCount,
    resultPerPage,
    filteredProductsCount,
  } = useSelector((state) => state.products);
  const keyword = params.keyword;

  const totalPages = Math.ceil(filteredProductsCount / resultPerPage);
  const initialPage = totalPages;

  const [currentPage, setCurrentPage] = useState(initialPage);

  const priceHandler = (e, newPrice) => {
    setPrice(newPrice);
  };

  const clearFilters = () => {
    setPrice([0, 500]);
    setCategory("");
    setRatings(0);
  };

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
      dispatch(clearErrors());
    }
    dispatch(getProducts(keyword, category, price, ratings, currentPage, searchKeyword));
  }, [
    dispatch,
    keyword,
    category,
    price,
    ratings,
    currentPage,
    error,
    enqueueSnackbar,
    
  ]);

  const reorderedProducts = [
    ...products.slice(-8),
    ...products.slice(0, -8)
  ];

  const handlePageChange = (e, value) => {
    setCurrentPage(value);
  };

  return (
    <>
      <MetaData title="All Products | PSBrand21" />

      <main className="w-full mt-14 sm:mt-0">
        <div className="flex flex-col sm:flex-row gap-3 mt-2 sm:mt-2 sm:mx-3 m-auto mb-7">
          <div className="flex flex-col w-full sm:w-1/5 px-1">
            <div className="flex flex-col bg-white rounded-sm shadow">
              <div className="flex items-center justify-between gap-5 px-4 py-2 border-b">
                <span
                  className="uppercase text-primary-blue text-xs cursor-pointer font-medium"
                  onClick={clearFilters}
                >
                  مسح جميع الفلاتر
                </span>
                <p className="text-lg font-medium">فلترة</p>
              </div>
              <div className="flex flex-col gap-2 py-3 text-sm overflow-hidden">
                <TextField
                  label="Search"
                  variant="outlined"
                  value={searchKeyword}
                  onChange={(e) => setSearchKeyword(e.target.value)}
                  fullWidth
                  size="small"
                />
                <div className="flex flex-col border-b px-4">
                  {/* <div
                    className="flex justify-between cursor-pointer py-2 pb-4 items-center"
                    onClick={() => setCategoryToggle(!categoryToggle)}
                  >
                    <p className="font-medium text-xs uppercase">الفئات</p>
                    {categoryToggle ? (
                      <ExpandLessIcon sx={{ fontSize: "20px" }} />
                    ) : (
                      <ExpandMoreIcon sx={{ fontSize: "20px" }} />
                    )}
                  </div> */}
                  {/* {categoryToggle && (
                    <div>
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="category"
                          onChange={(e) => setCategory(e.target.value)}
                          value={category}
                        >
                          <Grid container>
                            {categories.map((el, i) => (
                              <Grid item xs={6} key={i}>
                                <FormControlLabel
                                  value={el}
                                  control={<Radio size="small" />}
                                  label={el}
                                />
                              </Grid>
                            ))}
                          </Grid> */}
                        {/* </RadioGroup>
                      </FormControl> */}
                    {/* </div>
                  )} */}
                </div>
                <div className="flex flex-col border-b px-4">
                  <div
                    className="flex justify-between cursor-pointer py-2 pb-4 items-center"
                    onClick={() => setRatingsToggle(!ratingsToggle)}
                  >
                    <p className="font-medium text-xs uppercase">التقيمات</p>
                    {ratingsToggle ? (
                      <ExpandLessIcon sx={{ fontSize: "20px" }} />
                    ) : (
                      <ExpandMoreIcon sx={{ fontSize: "20px" }} />
                    )}
                  </div>
                  {ratingsToggle && (
                    <div className="flex flex-col pb-1">
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="ratings-radio-buttons-group"
                          onChange={(e) => setRatings(e.target.value)}
                          value={ratings}
                          name="ratings-radio-buttons"
                        >
                          {[4, 3, 2, 1].map((el, i) => (
                            <FormControlLabel
                              value={el}
                              key={i}
                              control={<Radio size="small" />}
                              label={
                                <span className="flex items-center text-sm">
                                  {el}
                                  <StarIcon sx={{ fontSize: "12px", mr: 0.5 }} />
                                  {" "} & above
                                </span>
                              }
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1">
            {!loading && products?.length === 0 && (
              <div className="flex flex-col items-center justify-center gap-3 bg-white shadow-sm rounded-sm p-6 sm:p-16">
                <img
                  draggable="false"
                  className="w-1/2 h-44 object-contain"
                  src="https://static-assets-web.flixcart.com/www/linchpin/fk-cp-zion/img/error-no-search-results_2353c5.png"
                  alt="Search Not Found"
                />
                <h1 className="text-2xl font-medium text-gray-900">
                  لا يوجد نتائج
                </h1>
                <p className="text-xl text-center text-primary-grey">
                  العنصر غير موجود
                </p>
              </div>
            )}
            {loading ? (
              <Loader />
            ) : (
              <div className="flex flex-col gap-4 pb-4 justify-center items-center w-full overflow-hidden bg-white">
                <div className="grid grid-cols-1 sm:grid-cols-4 w-full gap-4">
                  {reorderedProducts.filter(a => a.name.includes(searchKeyword)).map((product) => (
                    <Product {...product} key={product._id} />
                  ))}
                </div>
                {filteredProductsCount > resultPerPage && (
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default Products;
