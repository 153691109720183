const states = [
    {
      "code": "WB",
      "name": "الضفة الغربية"
    },
    
    {
      "code": "JR",
      "name": "القدس"
    },

    {
      "code": "PS",
      "name": "الداخل الفلسطيني"
    },
    
  ]

export default states;