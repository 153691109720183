import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PriceSidebar from "./PriceSidebar";
import Stepper from "./Stepper";
import { useSnackbar } from "notistack";
import { saveShippingInfo } from "../../actions/cartAction";
import { useNavigate } from "react-router-dom";
import MetaData from "../Layouts/MetaData";
import states from "../../utils/states";

const Shipping = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { cartItems } = useSelector((state) => state.cart);
  const { shippingInfo } = useSelector((state) => state.cart);

  const [address, setAddress] = useState(shippingInfo.address);
  const [city, setCity] = useState(shippingInfo.city);
  const [DeliveryCost, setDeliveryCost] = useState(20);
  const [error, setError] = useState(false);

  const [country, setCountry] = useState("PS");
  const [state, setState] = useState(shippingInfo.state);
  const [pincode, setPincode] = useState(0);
  const [phoneNo, setPhoneNo] = useState(shippingInfo.phoneNo);
  const [Name, setName] = useState(shippingInfo.Name);

  const shippingSubmit = (e) => {
    e.preventDefault();

    if (phoneNo.length < 10 || phoneNo.length > 10) {
      enqueueSnackbar("Invalid Phone Number", { variant: "error" });
      return;
    }
    window.localStorage.setItem("del", DeliveryCost);
    dispatch(
      saveShippingInfo({
        address,
        city,
        country,
        state,
        pincode,
        phoneNo,
        Name,
      })
    );
    navigate("/order/confirm");
  };
  const handleStateChange = (e) => {
    setState(e.target.value);
    // Update delivery cost based on the selected state
    switch (e.target.value) {
      case "WB":
        setDeliveryCost(20);
        break;
      case "JR":
        setDeliveryCost(30);
        break;
      case "PS":
        setDeliveryCost(70);
        break;
      default:
        setDeliveryCost(0);
        break;
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setPhoneNo(value);
    // Check if the phone number starts with "05" and has 10 digits
    const pattern = /^05\d{8}$/;
    setError(!pattern.test(value));
  };

  return (
    <>
      <MetaData title="PSBrand : تفاصيل الشحن" />
      <main className="w-full mt-20">
        {/* <!-- الصف --> */}
        <div className="flex flex-col sm:flex-row gap-3.5 w-full sm:w-11/12 mt-0 sm:mt-4 m-auto sm:mb-7 overflow-hidden">
          {/* <!-- عمود العربة --> */}
          <div className="flex-1">
            <Stepper activeStep={1}>
              <div className="w-full bg-white">
                <form
                  onSubmit={shippingSubmit}
                  autoComplete="off"
                  className="flex flex-col justify-start gap-3 w-full sm:w-3/4 mx-1 sm:mx-8 my-4"
                >
                  <FormControl fullWidth disabled={country ? false : true}>
                    <InputLabel id="state-select">مكان توصيل</InputLabel>
                    <Select
                      labelId="state-select"
                      id="state-select"
                      value={state}
                      label="المحافظة"
                      onChange={(e) => handleStateChange(e)}
                      required
                    >
                      {states.map((item) => (
                        <MenuItem key={item.code} value={item.code}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <TextField
                    value={Name}
                    onChange={(e) => setName(e.target.value)}
                    label="الاسم الكامل"
                    fullWidth
                    variant="outlined"
                    required
                  />

                  <TextField
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    label="المدينة"
                    fullWidth
                    variant="outlined"
                    required
                  />

                  <TextField
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    fullWidth
                    label="العنوان التفصيلي"
                    variant="outlined"
                    required
                  />

                  <div className="flex gap-6">
                    <TextField
                      value={phoneNo}
                      onChange={handleChange}
                      label="رقم الهاتف"
                      fullWidth
                      variant="outlined"
                      required
                      error={error}
                      helperText={
                        error
                          ? "يجب أن يبدأ رقم الهاتف بـ 05 ويتكون من 10 أرقام"
                          : ""
                      }
                    />
                  </div>

                  {/* <div className="flex gap-6">
                     
                            <TextField
                                label="مكان توصيل"
                                fullWidth
                                variant="outlined"
                            />
                        </div> */}

                  <div className="flex gap-6">
                    {/* <FormControl fullWidth>
                                <InputLabel id="country-select">الدولة</InputLabel>
                                <Select
                                    labelId="country-select"
                                    id="country-select"
                                    defaultValue={country}
                                    disabled
                                    label="الدولة"
                                    // onChange={(e) => setCountry(e.target.value)}
                                >
                                    <MenuItem value={'PS'}>فلسطين</MenuItem>
                                </Select>
                            </FormControl> */}
                  </div>

                  <button
                    type="submit"
                    className="bg-primary-orange w-full sm:w-1/3 my-2 py-3.5 text-sm font-medium text-white shadow hover:shadow-lg rounded-sm uppercase outline-none"
                  >
                    حفظ وتوصيل هنا
                  </button>
                </form>
              </div>
            </Stepper>
          </div>

          <PriceSidebar cartItems={cartItems} delevery={DeliveryCost} />
        </div>
      </main>
    </>
  );
};

export default Shipping;
