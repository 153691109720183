import React from 'react';
import styled from 'styled-components';
import './Nav.css';
import Logo from '../../../assets/images/logo.png';
import { Link } from 'react-router-dom';

const StickyHeader = styled.div`
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    top: 0;
    height: 50px;
    width: 100%;
    background-color: #8f654c;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff; /* Set text color to white */
    z-index: 1000; /* Ensure it stays above other content */
`;

const StyledLink = styled(Link)`
    text-decoration: none; /* Remove text decoration */
`;

export default function Top() {
    return (
        <StyledLink to='/'>
            <StickyHeader className='mainFont'>
                <img src={Logo} alt="Brand" width="60" height="60" />
                <>اهلا بكم في الموقع الرسمي للبراند الفلسطيني</>
            </StickyHeader>
        </StyledLink>
    );
}
