import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './index.css'; // Import your custom CSS file

const DeliveryPolicyPage = () => {
  return (
    <Container className="delivery-policy-container rtl">
      <h1 className="text-right">سياسة التوصيل</h1>
      <p className="text-right"><b>وقت التسليم: 2-7 أيام لمناطق الضفة الغربية، القدس والداخل الفلسطيني</b><br /></p>
      <Row className="mt-5">
        <Col xs={12} md={4} className="mb-3">
          <Card className="text-center p-3" style={{ height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            تكلفة التوصيل لمناطق الضفة الغربية: 20 شيكل
          </Card>
        </Col>
        <Col xs={12} md={4} className="mb-3">
          <Card className="text-center p-3" style={{ height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            تكلفة التوصيل للقدس: 30 شيكل
          </Card>
        </Col>
        <Col xs={12} md={4} className="mb-3">
          <Card className="text-center p-3" style={{ height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            تكلفة التوصيل لمناطق الداخل الفلسطيني: 70 شيكل
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default DeliveryPolicyPage;
