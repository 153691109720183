import { Link, useNavigate } from 'react-router-dom';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import InventoryIcon from '@mui/icons-material/Inventory';
import GroupIcon from '@mui/icons-material/Group';
import ReviewsIcon from '@mui/icons-material/Reviews';
import AddBoxIcon from '@mui/icons-material/AddBox';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import CloseIcon from '@mui/icons-material/Close';
import Avatar from '@mui/material/Avatar';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { logoutUser } from '../../../actions/userAction';
import tag from '../../../assets/images/Categories/hoodi.png';

const navMenu = [
    {
        icon: <EqualizerIcon />,
        label: "لوحة القيادة",
        ref: "/admin/dashboard",
    },
    {
        icon: <ShoppingBagIcon />,
        label: "الطلبات",
        ref: "/admin/orders",
    },
    {
        icon: <InventoryIcon />,
        label: "المنتجات",
        ref: "/admin/products",
    },
    {
        icon: <AddBoxIcon />,
        label: "إضافة منتج",
        ref: "/admin/new_product",
    },
    {
        icon: <GroupIcon />,
        label: "المستخدمون",
        ref: "/admin/users",
    },
    {
        icon: <ReviewsIcon />,
        label: "التقييمات",
        ref: "/admin/reviews",
    },
    {
        icon: <AccountBoxIcon />,
        label: "ملفي",
        ref: "/account",
    },
    {
        icon: <LogoutIcon />,
        label: "تسجيل الخروج",
    },
];

const Sidebar = ({ activeTab, setToggleSidebar }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const { user } = useSelector((state) => state.user);

    const handleLogout = () => {
        dispatch(logoutUser());
        enqueueSnackbar("تم تسجيل الخروج بنجاح", { variant: "success" });
        navigate("/login");
    }

    return (
        <>
            {/* Your sidebar content goes here */}
            <aside className="fixed left-0 top-0 h-full bg-gray-800 text-white w-72 overflow-y-auto">
                <div className="p-4">
                    <h2 className="text-xl font-bold mb-4">Admin Sidebar</h2>
                    <ul>
                        {navMenu.map((item, index) => (
                            <li key={index} className="mb-2">
                                {item.ref ? (
                                    <Link to={item.ref} onClick={() => setToggleSidebar(false)}>
                                        {item.icon}
                                        <span className="ml-2">{item.label}</span>
                                    </Link>
                                ) : (
                                    <button onClick={handleLogout}>
                                        {item.icon}
                                        <span className="ml-2">{item.label}</span>
                                    </button>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            </aside>
        </>
    );
};

export default Sidebar;
