import React, { useState, useEffect, useRef } from "react";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaCartPlus, FaBars, FaTimes, FaClipboardList, FaInfoCircle, FaSignOutAlt, FaSignInAlt } from 'react-icons/fa';
import Top from "./Top";
import { categories } from '../../../utils/constants';
import { useSelector, useDispatch } from 'react-redux';
import { logoutUser } from '../../../actions/userAction';
import Cookie from 'js-cookies';
import './Nav.css'; // Import the CSS file

const MyNavbar = () => {
  const { isAuthenticated } = useSelector(state => state.user);
  const dispatch = useDispatch();

  const [sidebar, setSidebar] = useState(false);
  const sidebarRef = useRef(null);

  const showSidebar = () => setSidebar(!sidebar);

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setSidebar(false);
    }
  };

  useEffect(() => {
    if (sidebar) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidebar]);

  const Logout = () => {
    Cookie.removeItem("token");
    window.location.reload();
  };

  return (
    <>
      <Top />
      <Navbar expand="lg" className="navbar-custom" dir="rtl">
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={showSidebar} className="d-lg-none">
            <FaBars className="menu-bars" />
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav" className="d-none d-lg-block">
            <Nav className="ms-auto">
              <Nav.Link href="/"> الصفحة الرئيسية</Nav.Link>
              {categories.map((item, i) => (
                <Nav.Link href={`/products?category=${item}`} key={i}>{item}</Nav.Link>
              ))}
              {isAuthenticated && 
                <Nav.Link href="/orders"> طلباتي</Nav.Link>
              }
              <NavDropdown title="عنا" id="basic-nav-dropdown">
                <NavDropdown.Item href="About"><FaInfoCircle /> من نحن</NavDropdown.Item>
                <NavDropdown.Item href="DeliveryPolicyPage"><FaInfoCircle /> سياسة توصيل</NavDropdown.Item>
              </NavDropdown>
              
                             <Nav.Link href="/wishlist">قائمة المفضلة</Nav.Link>
                             {isAuthenticated ? 
                <Nav.Link onClick={Logout}>تسجيل الخروج</Nav.Link> 
                : <Nav.Link href="login"> تسجيل الدخول</Nav.Link>
              }

            </Nav>
          </Navbar.Collapse>
          <div className="cart-icon">
            <Nav.Link href="/cart"><FaCartPlus /></Nav.Link>
          </div>
        </Container>
      </Navbar>

      <nav ref={sidebarRef} className={sidebar ? 'nav-menu active' : 'nav-menu'}>
        <ul className='nav-menu-items'>
          <li className='navbar-toggle' onClick={showSidebar}>
            <FaTimes className="menu-bars" />
          </li>
          <li>
            <Nav.Link href="/" className="nav-text" onClick={showSidebar}> الصفحة الرئيسية</Nav.Link>
          </li>
          {categories.map((item, i) => (
            <li key={i}>
              <Nav.Link href={`/products?category=${item}`} className="nav-text" onClick={showSidebar}>{item}</Nav.Link>
            </li>
          ))}
          {isAuthenticated && 
            <li>
              <Nav.Link href="/orders" className="nav-text" onClick={showSidebar}>طلباتي</Nav.Link>
            </li>
          }

          <li>
          <Nav.Link className="nav-text" href="/wishlist">قائمة المفضلة</Nav.Link>

          </li>

          <li>
            <NavDropdown title="عنا" id="basic-nav-dropdown" className="nav-text">
              <NavDropdown.Item href="About"><FaInfoCircle /> من نحن</NavDropdown.Item>
              <NavDropdown.Item href="DeliveryPolicyPage"><FaInfoCircle /> سياسة توصيل</NavDropdown.Item>
            </NavDropdown>
          </li>
          <li>
            {isAuthenticated ? 
              <Nav.Link onClick={() => { Logout(); showSidebar(); }} className="nav-text">تسجيل الخروج</Nav.Link> 
              : <Nav.Link href="login" className="nav-text" onClick={showSidebar}> تسجيل الدخول</Nav.Link>
            }
          </li>
        </ul>
      </nav>
    </>
  );
};

export default MyNavbar;
