// Categories Used In Entire App
import Img from '../assets/images/hero2.jpeg'
import Img2 from '../assets/images/hero3.jpeg'

export const categories = [
    "بلايزنا",
    "كفراتنا",
    "بكجاتنا",
    "شنط قماش",
    "المنتجات المميزة",
    "مطبوعاتنا",
    "منتجات اخرى",
    "جديدنا",

    "PS sports",
];



// Product Sliders Offers
export const offerProducts = [
    {
        image: Img,
        name: "تيشيرت فلسطيني رجالي",
        offer: "100 NIS",
        tag: "تيشرتات",
    },
    {
        image: Img2,
        name: "هودي فلسطيني",
        offer: "120 NIS",
        tag: "هوديات",
    },
    {
        image: Img,
        name: "تيشيرت فلسطيني رجالي",
        offer: "100 NIS",
        tag: "تيشرتات",
    },
    {
        image: Img2,
        name: "هودي فلسطيني",
        offer: "120 NIS",
        tag: "هوديات",
    },
    {
        image: Img,
        name: "تيشيرت فلسطيني رجالي",
        offer: "100 NIS",
        tag: "تيشرتات",
    },
    {
        image: Img2,
        name: "هودي فلسطيني",
        offer: "120 NIS",
        tag: "هوديات",
    },
   
]