import React from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { addItemsToCart, removeItemsFromCart } from '../../actions/cartAction';
import { getDeliveryDate, getDiscount } from '../../utils/functions';
import { saveForLater } from '../../actions/saveForLaterAction';
import { Link } from 'react-router-dom';

const CartItem = ({ product, name, seller, price, cuttedPrice, image, stock, quantity, inCart, size, selectedColor }) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const increaseQuantity = (id, quantity, stock) => {
        const newQty = quantity + 1;
        if (quantity >= stock) {
            enqueueSnackbar("الكمية القصوى للطلب", { variant: "warning" });
            return;
        }
        dispatch(addItemsToCart(id, size, selectedColor, newQty));
    };

    const decreaseQuantity = (id, quantity) => {
        const newQty = quantity - 1;
        if (quantity <= 1) return;
        dispatch(addItemsToCart(id, size, selectedColor, newQty));
    };

    const removeCartItem = (id) => {
        dispatch(removeItemsFromCart(id));
        enqueueSnackbar("تمت إزالة المنتج من السلة", { variant: "success" });
    };

    const saveForLaterHandler = (id) => {
        dispatch(saveForLater(id));
        removeCartItem(id);
        enqueueSnackbar("تم الحفظ لوقت لاحق", { variant: "success" });
    };

    return (
        <div className="flex flex-col gap-3 py-5 pl-2 sm:pl-6 border-b overflow-hidden" key={product}>
            <Link to={`/product/${product}`} className="flex flex-col sm:flex-row gap-5 items-stretch w-full group">
                <div className="w-full sm:w-1/6 h-28 flex-shrink-0">
                    <img draggable="false" className="h-full w-full object-contain" src={image} alt={name} />
                </div>
                <div className="flex flex-col sm:gap-5 w-full pl-6">
                    <div className="flex flex-col gap-0.5 sm:w-3/5">
                        <p className="group-hover:text-primary-blue">{name.length > 42 ? `${name.substring(0, 42)}...` : name}</p>
                        <span className="text-sm text-gray-500">البائع: {seller}</span>
                    </div>
                    <div className="flex flex-col sm:gap-2">
                        <span className="text-xs text-gray-500">سياسة الاستبدال في غضون 7 أيام</span>
                    </div>
                </div>
            </Link>
            <div className="flex justify-between pr-4 sm:pr-0 sm:justify-start sm:gap-6">
                <div className="flex gap-1 items-center">
                    <span 
                        onClick={() => decreaseQuantity(product, quantity)} 
                        className="w-7 h-7 text-3xl font-light bg-gray-50 rounded-full border flex items-center justify-center cursor-pointer">
                        -
                    </span>
                    <input 
                        className="w-11 border outline-none text-center rounded-sm py-0.5 text-gray-700 font-medium text-sm qtyInput" 
                        value={quantity} 
                        readOnly 
                    />
                    <span 
                        onClick={() => increaseQuantity(product, quantity, stock)} 
                        className="w-7 h-7 text-xl font-light bg-gray-50 rounded-full border flex items-center justify-center cursor-pointer">
                        + 
                    </span>
                </div>
                {inCart && (
                    <div className="flex gap-2">
                        <button 
                            onClick={() => saveForLaterHandler(product)} 
                            className="font-medium hover:text-primary-blue">
                            حفظ لوقت لاحق
                        </button>
                        <button 
                            onClick={() => removeCartItem(product)} 
                            className="font-medium hover:text-red-600">
                            إزالة
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CartItem;
