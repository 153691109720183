import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useSnackbar } from 'notistack';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearErrors, registerUser } from '../../actions/userAction';
import BackdropLoader from '../Layouts/BackdropLoader';
import MetaData from '../Layouts/MetaData';

const Register = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const { loading, isAuthenticated, error } = useSelector((state) => state.user);

    const [user, setUser] = useState({
        name: "",
        email: "",
        gender: "",
        password: "",
        cpassword: "",
    });

    const { name, email, gender, password, cpassword } = user;

    const avatar = true;

    const handleRegister = (e) => {
        e.preventDefault();
        if (password.length < 8) {
            enqueueSnackbar("Password length must be at least 8 characters", { variant: "warning" });
            return;
        }
        if (password !== cpassword) {
            enqueueSnackbar("Password Doesn't Match", { variant: "error" });
            return;
        }
        if (!avatar) {
            enqueueSnackbar("Select Avatar", { variant: "error" });
            return;
        }

        const formData = new FormData();
        formData.set("name", name);
        formData.set("email", email);
        formData.set("gender", gender);
        formData.set("password", password);

        dispatch(registerUser(formData));
    };

    const handleDataChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        if (error) {
            enqueueSnackbar(error, { variant: "error" });
            dispatch(clearErrors());
        }
        if (isAuthenticated) {
            navigate('/');
        }
    }, [dispatch, error, isAuthenticated, navigate, enqueueSnackbar]);

    return (
        <>
            <MetaData title="التسجيل | Flipkart" />
            {loading && <BackdropLoader />}
            <main className="min-h-screen flex items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-md w-full space-y-8">
                    <div>
                        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">إنشاء حساب جديد</h2>
                    </div>
                    <form className="mt-8 space-y-6" onSubmit={handleRegister} encType="multipart/form-data">
                        <div className="rounded-md shadow-sm -space-y-px">
                            <div>
                                <TextField
                                    fullWidth
                                    id="full-name"
                                    label="الاسم الكامل"
                                    name="name"
                                    value={name}
                                    onChange={handleDataChange}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                />
                            </div>
                            <div>
                                <TextField
                                    fullWidth
                                    id="email"
                                    label="البريد الإلكتروني"
                                    type="email"
                                    name="email"
                                    value={email}
                                    onChange={handleDataChange}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                />
                            </div>
                            <div>
                                <RadioGroup
                                    row
                                    aria-labelledby="radio-buttons-group-label"
                                    name="gender"
                                    value={gender}
                                    onChange={handleDataChange}
                                    className="mt-4"
                                >
                                    <FormControlLabel value="male" control={<Radio required />} label="ذكر" />
                                    <FormControlLabel value="female" control={<Radio required />} label="أنثى" />
                                </RadioGroup>
                            </div>
                            <div className="flex space-x-4">
                                <TextField
                                    fullWidth
                                    id="password"
                                    label="كلمة المرور"
                                    type="password"
                                    name="password"
                                    value={password}
                                    onChange={handleDataChange}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                />
                                <TextField
                                    fullWidth
                                    id="confirm-password"
                                    label="تأكيد كلمة المرور"
                                    type="password"
                                    name="cpassword"
                                    value={cpassword}
                                    onChange={handleDataChange}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                />
                            </div>
                        </div>
                        <div>
                            <button
                                type="submit"
                                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                تسجيل
                            </button>
                        </div>
                        <div className="text-center">
                            <Link to="/login" className="font-medium text-indigo-600 hover:text-indigo-500">
                                مستخدم حالي؟ تسجيل الدخول
                            </Link>
                        </div>
                    </form>
                </div>
            </main>
        </>
    );
};

export default Register;
