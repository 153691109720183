import React from "react";
import { useSelector } from "react-redux";
import Product from "../ProductSlider/Product";
import Loader from "../../Layouts/Loader";
import './slider.css';

const DealSlider = ({ title }) => {
  const { products, loading } = useSelector((state) => state.products);

  // Filtering products by category title
  const filteredProducts = products
    ?.filter((product) => product.category === title)
    .slice(0, 4);

  return (
    <section
      className="bg-white w-full shadow overflow-hidden"
      style={{ direction: "rtl" }}
    >
      <div className="flex px-6 py-4 justify-center items-center">
        <div className="title flex flex-col gap-0.5">
          <h1 className="text-xl font-medium rakkas-regular text-center">
            {title}
          </h1>
        </div>
      </div>

      <hr />
      {loading ? (
        <Loader />
      ) : (
        <div className="flex flex-col gap-2 pb-4 justify-center items-center w-full overflow-hidden bg-white">
          <div className="grid grid-cols-2 sm:grid-cols-4 w-full place-content-start overflow-hidden pb-4 border-b">
            {filteredProducts.length > 0 ? (
              filteredProducts.map((product) => (
                <Product {...product} key={product._id} />
              ))
            ) : (
              <div className="text-center text-gray-500">
                No products found for {title}
              </div>
            )}
          </div>
        </div>
      )}
    </section>
  );
};

export default DealSlider;
