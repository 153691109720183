import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CircleIcon from '@mui/icons-material/Circle';
import { Link } from 'react-router-dom';
import { formatDate } from '../../utils/functions';

const OrderItem = (props) => {

    const { orderId, name, image, price, quantity, createdAt, deliveredAt, orderStatus, totalPrice } = props;

    return (
        <Link to={`/order_details/${orderId}`} className="flex p-4 items-start bg-white border rounded gap-2 sm:gap-0 hover:shadow-lg" style={{ flexDirection: "row-reverse" }}>
        {/* <!-- حاوية الصورة --> */}
        
        <div className="w-full sm:w-32 h-20">
            <img draggable="false" className="h-full w-full object-contain" src={image} alt={name} />
        </div>
        {/* <!-- حاوية الصورة --> */}
    
        {/* <!-- حاوية وصف الطلب --> */}
        <div className="flex flex-col sm:flex-row justify-between w-full" style={{ flexDirection: "row-reverse" }}>
    
            <div className="flex flex-col gap-1 overflow-hidden">
                <p className="text-sm">{name.length > 40 ? `${name.substring(0, 40)}...` : name}</p>
                <p className="text-xs text-gray-500 mt-2">الكمية: {quantity}</p>
                <p className="text-xs text-gray-500">الإجمالي: ₪ {(quantity * totalPrice).toLocaleString()}</p>
            </div>
    
            <div className="flex flex-col sm:flex-row mt-1 sm:mt-0 gap-2 sm:gap-20 sm:w-1/2" style={{ flexDirection: "row-reverse" }}>
                <p className="text-sm">₪ {totalPrice.toLocaleString()}</p>
    
                <div className="flex flex-col gap-1.5">
                    <p className="text-sm font-medium flex items-center gap-1" style={{ flexDirection: "row-reverse" }}>
                        {orderStatus === "Shipped" ? (
                            <>
                                تم الشحن
                                <span className="text-primary-orange pb-0.5">
                                    <CircleIcon sx={{ fontSize: "14px" }} />
                                </span>
                            </>
                        ) : orderStatus === "Delivered" ? (
                            <>
                                 {formatDate(deliveredAt)} تم التوصيل بتاريخ
                                <span className="text-primary-green pb-0.5">
                                    <CircleIcon sx={{ fontSize: "14px" }} />
                                </span>
                            </>
                        ) : (
                            <>
                                {formatDate(createdAt)} تم الطلب بتاريخ
                                <span className="text-primary-green pb-0.5">
                                    <RadioButtonUncheckedIcon sx={{ fontSize: "14px" }} />
                                </span>
                            </>
                        )}
                    </p>
                    {orderStatus === "Delivered" ?
                        <p className="text-xs ml-1">لقد تم {orderStatus} العنصر الخاص بك</p>
                        : orderStatus === "Shipped" ?
                            <p className="text-xs ml-1">لقد تم {orderStatus} العنصر الخاص بك</p> :
                            <p className="text-xs ml-1">قام البائع بمعالجة طلبك</p>
                    }
                </div>
            </div>
    
        </div>
        {/* <!-- حاوية وصف الطلب --> */}
    
    </Link>
    
    );
};

export default OrderItem;
