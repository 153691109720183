import StarIcon from '@mui/icons-material/Star';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Link } from 'react-router-dom';
import { getDiscount } from '../../utils/functions';
import { useDispatch, useSelector } from 'react-redux';
import { addToWishlist, removeFromWishlist } from '../../actions/wishlistAction';
import { useSnackbar } from 'notistack';
import './card.css'
import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';

const Product = ({ _id, name, images, ratings, numOfReviews, price, cuttedPrice }) => {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const { wishlistItems } = useSelector((state) => state.wishlist);

    const itemInWishlist = wishlistItems.some((i) => i.product === _id);

    const addToWishlistHandler = () => {
        if (itemInWishlist) {
            dispatch(removeFromWishlist(_id));
            enqueueSnackbar("Remove From Wishlist", { variant: "success" });
        } else {
            dispatch(addToWishlist(_id));
            enqueueSnackbar("Added To Wishlist", { variant: "success" });
        }
    }

    return (
        <div className="flex flex-col gap-2 px-4 py-6 relative hover:shadow-lg rounded-sm border border-gray-200">
            {/* Image & Product Title */}
            <Link to={`/product/${_id}`} className="flex flex-col items-center" style={{textDecoration:'none'}}>
                <div className="w-44 h-48 overflow-hidden rounded-md">
                    <img className="w-full h-full object-cover" src={images && images[0].url} alt={name} />
                </div>
                <h2 className="text-sm mt-4 text-center" >{name.length > 85 ? `${name.substring(0, 85)}...` : name}</h2>
            </Link>

            {/* Product Description */}
            <div className="flex flex-col gap-2 items-center">
                {/* Rating Badge */}

                {/* Price Container */}
                <div className="flex items-center gap-1.5 text-md font-medium">
                    <span>{price.toLocaleString()} ₪</span>
                    {/* <span className="text-gray-500 line-through text-xs">{cuttedPrice.toLocaleString()} ₪</span> */}
                    {/* <span className="text-xs text-primary-green">{getDiscount(price, cuttedPrice)}% off</span> */}
                </div>
            </div>

            {/* Wishlist Badge */}
            {/* <span onClick={addToWishlistHandler} className={`${itemInWishlist ? "text-red-500" : "hover:text-red-500 text-gray-300"} absolute top-4 right-4 cursor-pointer`}><FavoriteIcon sx={{ fontSize: "18px" }} /></span> */}
        </div>
    );

    
};

export default Product;


// import React from 'react';
// import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';
// import IMGURL from '../../assets/images/hero2.jpeg'

// const ProductCard = ({ product }) => {
//     return (
//         <Card>
//             <CardActionArea>
//                 <CardMedia
//                     component="img"
//                     height="200"
//                     image={IMGURL}
//                     alt="test"
//                 />
//                 <CardContent>
//                     <Typography gutterBottom variant="h5" component="div">
//                        Test
//                     </Typography>
//                     <Typography variant="body2" color="text.secondary">
//                       test description
//                     </Typography>
//                     <Typography variant="body2" color="text.primary">
//                         Price: 22
//                     </Typography>
//                 </CardContent>
//             </CardActionArea>
//         </Card>
//     );
// }

// export default ProductCard;
