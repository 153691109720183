import { useEffect, useState } from 'react';
import Categories from '../Layouts/Categories';
import Banner from './Banner/Banner';
import DealSlider from './DealSlider/DealSlider';
import MyPoupup from './Poupup'; // Import your popup component

import { useDispatch, useSelector } from 'react-redux';
import { clearErrors, getSliderProducts } from '../../actions/productAction';
import { useSnackbar } from 'notistack';
import MetaData from '../Layouts/MetaData';

const Home = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { error } = useSelector((state) => state.products);

  const [showPopup, setShowPopup] = useState(true); // Set to true to show popup by default

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
      dispatch(clearErrors());
    }
    dispatch(getSliderProducts());
  }, [dispatch, error, enqueueSnackbar]);

  return (
    <>
      <MetaData title="PSBrand21 | البراند الفلسطيني للملابس" />

      <main className="flex flex-col gap-3 px-2 mt-16 sm:mt-2">
        <Banner />
        <Categories />
        <hr />
        <DealSlider title={"المنتجات المميزة"} />
        <hr />
        <DealSlider title={"جديدنا"} />
        <hr />
        <DealSlider title={"بلايزنا"} />
        <hr />
        <DealSlider title={"بكجاتنا"} />
        <hr />
        <DealSlider title={"شنط قماش"} />
        <hr />
        <DealSlider title={"كفراتنا"} />
        <hr />
        <DealSlider title={"مطبوعاتنا"} />

        {/* Popup component */}
        {showPopup && <MyPoupup onClose={() => setShowPopup(false)} />}
      </main>
    </>
  );
};

export default Home;
