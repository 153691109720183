import axios from '../../utils/axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PriceSidebar from './PriceSidebar';
import Stepper from './Stepper';
import { addItemsToCart, removeItemsFromCart } from '../../actions/cartAction';
import Cookies from 'js-cookies'

// import {
//     CardNumberElement,
//     CardCvcElement,
//     CardExpiryElement,
//     useStripe,
//     useElements,
// } from '@stripe/react-stripe-js';
import { clearErrors } from '../../actions/orderAction';
import { useSnackbar } from 'notistack';
import { post } from '../../utils/paytmForm';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import MetaData from '../Layouts/MetaData';

const Payment = () => {

    const dispatch = useDispatch();
    // const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    // const stripe = useStripe();
    // const elements = useElements();
    // const paymentBtn = useRef(null);

    const [payDisable, setPayDisable] = useState(false);
    const [Loading, setLoading] = useState(false);
    const usertoken = Cookies.getItem('token')

    const { shippingInfo, cartItems } = useSelector((state) => state.cart);
    const { user } = useSelector((state) => state.user);
    const { error } = useSelector((state) => state.newOrder);
    var del = window.localStorage.getItem("del")
    
    var dprice = parseInt(del)

    const totalPrice = cartItems.reduce((sum, item) => sum + (item.price * item.quantity), 0) + dprice;

    const paymentData = {
        shippingInfo: shippingInfo,
        orderItems: cartItems,
        paymentInfo:"",
        totalPrice: Math.round(totalPrice),
    };

    // const order = {
    //     shippingInfo,
    //     orderItems: cartItems,
    //     totalPrice,
    // }

    const submitHandler = async (e) => {
        e.preventDefault();
        setLoading(true)
        // paymentBtn.current.disabled = true;
        // setPayDisable(true);
        var token = Cookies.getItem("token")

        try {


            if(usertoken){
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    },
                };
    
                const  data  = await axios.post(
                    '/api/v1/order/new',
                    paymentData,
                    config,
                );
                window.location.replace('/orders')
            }else{
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                    },
                };
    
                const  data  = await axios.post(
                    '/api/v1/order/new',
                    paymentData,
                    config,
                );
                window.alert(data.data.order._id + " " + "كود تيلجرام" + "تم ارسال الطلب")

                setTimeout(() =>{
                    window.location.replace('/')
                },2000)
            }

            window.localStorage.removeItem("cartItems")
            // if (data.status == 201){
            //     cartItems.map(item => {
            //         dispatch(removeItemsFromCart(item.product));
            //         enqueueSnackbar("تم طلب المنتجات بنجاح", { variant: "success" });
            //     })
            //     window.location.replace('/orders')
            // }
    
            // if (!stripe || !elements) return;

            // const result = await stripe.confirmCardPayment(client_secret, {
            //     payment_method: {
            //         card: elements.getElement(CardNumberElement),
            //         billing_details: {
            //             name: user.name,
            //             email: user.email,
            //             address: {
            //                 line1: shippingInfo.address,
            //                 city: shippingInfo.city,
            //                 country: shippingInfo.country,
            //                 state: shippingInfo.state,
            //                 postal_code: shippingInfo.pincode,
            //             },
            //         },
            //     },
            // });

            // if (result.error) {
            //     paymentBtn.current.disabled = false;
            //     enqueueSnackbar(result.error.message, { variant: "error" });
            // } else {
            //     if (result.paymentIntent.status === "succeeded") {

            //         order.paymentInfo = {
            //             id: result.paymentIntent.id,
            //             status: result.paymentIntent.status,
            //         };

            //         dispatch(newOrder(order));
            //         dispatch(emptyCart());

            //         navigate("/order/success");
            //     } else {
            //         enqueueSnackbar("Processing Payment Failed!", { variant: "error" });
            //     }
            // }

        } catch (error) {
            // paymentBtn.current.disabled = false;
            setPayDisable(false);
            enqueueSnackbar(error, { variant: "error" });
         
                window.alert("error")
   
        }finally{
            setLoading(false)
        }
    };

    useEffect(() => {
        if (error) {
            dispatch(clearErrors());
            enqueueSnackbar(error, { variant: "error" });
        }
    }, [dispatch, error, enqueueSnackbar]);



    return (
        <>
            <MetaData title="PSBrand21 | تآكيد الدفع" />

            <main className="w-full mt-20">

                {/* <!-- row --> */}
                <div className="flex flex-col sm:flex-row gap-3.5 w-full sm:w-11/12 mt-0 sm:mt-4 m-auto sm:mb-7">

                    {/* <!-- cart column --> */}
                    <div className="flex-1">

                        <Stepper activeStep={3}>
                            <div className="w-full bg-white">

                                <form onSubmit={(e) => submitHandler(e)} autoComplete="off" className="flex flex-col justify-start gap-2 w-full mx-8 my-4 overflow-hidden">
                                   

                                   {Loading ? <></> : <input type="submit" value={`تآكيد سعر ${parseInt(totalPrice.toLocaleString()) } شيكل`}  className={`bg-primary-orange cursor-pointer w-1/2 sm:w-1/4 my-2 py-3 font-medium text-white shadow hover:shadow-lg rounded-sm uppercase outline-none`} />} 

                                </form>

                                {/* stripe form */}
                                {/* <form onSubmit={(e) => submitHandler(e)} autoComplete="off" className="flex flex-col justify-start gap-3 w-full sm:w-3/4 mx-8 my-4">
                                <div>
                                    <CardNumberElement />
                                </div>
                                <div>
                                    <CardExpiryElement />
                                </div>
                                <div>
                                    <CardCvcElement />
                                </div>
                                <input ref={paymentBtn} type="submit" value="Pay" className="bg-primary-orange w-full sm:w-1/3 my-2 py-3.5 text-sm font-medium text-white shadow hover:shadow-lg rounded-sm uppercase outline-none cursor-pointer" />
                            </form> */}
                                {/* stripe form */}

                            </div>
                        </Stepper>
                    </div>

                    <PriceSidebar cartItems={cartItems} delevery={dprice}/>
                </div>
            </main>
        </>
    );
};

export default Payment;