import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaFacebookF, FaInstagram, FaTiktok, FaWhatsapp } from 'react-icons/fa';

function ArabicFooter() {
  return (
<>
<br />
<footer className="bg-dark text-white py-5 c-footer">
      <Container>
        <Row className="justify-content-center">
          <Col className="text-center">
            <h4>تواصل معنا</h4>
            <ul className="list-unstyled list-inline">
              <li className="list-inline-item">
                <a href="https://www.facebook.com/PS.Brand21/"><FaFacebookF /></a>
              </li>
              <li className="list-inline-item">
                <a href="https://www.tiktok.com/@ps.brand21?is_from_webapp=1&sender_device=pc"><FaTiktok /></a>
              </li>
              <li className="list-inline-item">
                <a href="https://www.instagram.com/ps.brand21/"><FaInstagram /></a>
              </li>
              <li className="list-inline-item">
                <a href="https://api.whatsapp.com/message/NBPLGWBEPJFXA1?autoload=1&app_absent=0"><FaWhatsapp /></a>
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="text-center">
            <p>جميع الحقوق محفوظة لدى البراند الفلسطيني &copy; 2024</p>
          </Col>
        </Row>
      </Container>
    </footer>
</>
  
  );
}

export default ArabicFooter;
