import "bootstrap/dist/css/bootstrap.min.css";


const PriceSidebar = ({ cartItems }) => {
    var del = window.localStorage.getItem("del")

    return (
        <div className="flex sticky top-16 sm:h-screen flex-col sm:w-4/12 sm:px-1">

            {/* <!-- nav tiles --> */}
            <div className="flex flex-col bg-white rounded-sm shadow">
                <h1 className="px-6 py-3 border-b font-medium text-gray-500 rtl text-right">تفاصيل سعر</h1>

                <div className="flex flex-col gap-4 p-6 pb-3">
                    <p className="flex justify-between"> ({cartItems.length} عنصر) <span>₪  {cartItems.reduce((sum, item) => sum + (item.cuttedPrice * item.quantity), 0).toLocaleString()} السعر</span></p>
                    <p className="flex justify-between">₪  <span className="text-primary-green">- {cartItems.reduce((sum, item) => sum + ((item.cuttedPrice * item.quantity) - (item.price * item.quantity)), 0).toLocaleString()} الخصومات</span></p>
                    <p className="flex justify-between">₪ {parseInt(del) ? parseInt(del) : 0}<span className="text-primary-green">توصيل</span></p>

                    <div className="border border-dashed"></div>
                    <p className="flex justify-between text-lg font-medium"><span>₪  {cartItems.reduce((sum, item) => sum + (item.price * item.quantity) + parseInt(del), 0).toLocaleString()} المجموع</span></p>
                    <div className="border border-dashed"></div>

 
                </div>

            </div>
            {/* <!-- nav tiles --> */}

        </div>
    );
};

export default PriceSidebar;
