import axios from 'axios';

// const api = axios.create({
//   baseURL: 'http://localhost:4000'
// });

const api = axios.create({
    baseURL: 'https://psbrand-api.vercel.app/'
  });

// const api = axios.create({
//     baseURL: 'https://psbrand-gaeda0g6cnd2hfd5.eastus-01.azurewebsites.net'
//   });
  
// Request interceptor
api.interceptors.request.use(request => {
  console.log('Request:', request);
  return request;
}, error => {
  console.error('Request error:', error);
  return Promise.reject(error);
});

// Response interceptor
api.interceptors.response.use(response => {
  console.log('Response:', response);
  return response;
}, error => {
  console.error('Response error:', error);
  return Promise.reject(error);
});

export default api;
