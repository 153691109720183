import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './index.css'; // Import your custom CSS file
import MetaData from '../Layouts/MetaData';

const AboutPage = () => {
  return (
    <Container className="rtl">
    <MetaData title="عنا | PSBrand21" />

      <Row className="mt-5">
        <Col>
          <h1 className="text-right">من نحن</h1>
          <p className="text-right">
            وجدت منصة PS بدافع وطني فلسطيني من رحم التيقن بأحقية الشعب الفلسطيني بهذه الأرض من البحر الى النهر ،
            ومن الجليل شمالاً إلى النقب جنوباً، حيث أننا اخترنا هذا الاسم الذي يختصر كلمة (فلسطين) وهو اختصار عالمي
            معروف، ولأن الشباب هم الركيزة في بناء الدول فخرجت فكرة مشروعنا من عقل شبابي فلسطيني يؤمن بقضيته
            وأحقيته في العيش مبدعاً، مبتكر ومصمم على تحقيق النجاح.
          </p>
        </Col>
      </Row>

      {/* Section: What We Offer */}
      <Row className="mt-5">
        <Col>
          <h2 className="text-right">ماذا نقدم ؟</h2>
          <p className="text-right">
            نوفر من خلال PS تيشرتات مصنوعة في فلسطين بتصاميم فلسطينية معبرة، كما ونسعى من خلال تصاميمنا
            الى تثبيت الهوية الفلسطينية في مجالات مختلفة منها: (الثقافية، الاجتماعية والوطنية) كما أننا
            نهتم بكل التفاصيل الخاصة في الصناعة والطباعة لنقدم منتجاً مميزاً يستحق أن يكون فلسطينياً.
          </p>
        </Col>
      </Row>

      {/* Section: Delivery Costs */}
   

      {/* Section: Our Vision */}
      <Row className="mt-5">
        <Col>
          <h2 className="text-right">تطلعاتنا</h2>
          <p className="text-right">
            نتطلع في الـBrand PS الذي يتكون من فريق شبابي فلسطيني الى تطوير براند فلسطيني يعبر بكل فخر
            واعتزاز عن الهوية الفلسطينية، ونسعى لتحقيق مكانة مرموقة لمنصتنا ومنتجاتنا في السوق الوطني
            والعالمي.
          </p>
        </Col>
      </Row>
    </Container>
  );
};
 
export default AboutPage;
