import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearErrors, loginUser } from '../../actions/userAction';
import { useSnackbar } from 'notistack';
import BackdropLoader from '../Layouts/BackdropLoader';
import MetaData from '../Layouts/MetaData';

const Login = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();

    const { loading, isAuthenticated, error } = useSelector((state) => state.user);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleLogin = (e) => {
        e.preventDefault();
        dispatch(loginUser(email, password));
    }

    const redirect = location.search ? location.search.split("=")[1] : "account";

    useEffect(() => {
        if (error) {
            enqueueSnackbar(error, { variant: "error" });
            dispatch(clearErrors());
        }
        if (isAuthenticated) {
            navigate(`/${redirect}`)
        }
    }, [dispatch, error, isAuthenticated, redirect, navigate, enqueueSnackbar]);

    return (
        <>
        <MetaData title="تسجيل الدخول | PSBrand" />
    
        {loading && <BackdropLoader />}
        <main className="w-full mt-12 sm:pt-20 sm:mt-0">
    
            {/* <!-- صف --> */}
            <div className="flex sm:w-4/6 sm:mt-4 m-auto mb-7 bg-white shadow-lg rtl">
    
                {/* <!-- عمود تسجيل الدخول --> */}
                <div className="flex-1 overflow-hidden">
    
                    {/* <!-- حاوية تحرير المعلومات --> */}
                    <div className="text-center py-10 px-4 sm:px-14">
    
                        {/* <!-- حاوية الإدخال --> */}
                        <form onSubmit={handleLogin}>
                            <div className="flex flex-col w-full gap-4">
    
                                <TextField
                                    fullWidth
                                    id="email"
                                    label="البريد الإلكتروني"
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                                <TextField
                                    fullWidth
                                    id="password"
                                    label="كلمة المرور"
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                                {/* <span className="text-xxs text-red-500 font-medium text-left mt-0.5">Please enter valid Email ID/Mobile number</span> */}
    
                                {/* <!-- حاوية الزر --> */}
                                <div className="flex flex-col gap-2.5 mt-2 mb-32">
                                    {/* <p className="text-xs text-primary-grey text-left">من خلال الاستمرار، فإنك توافق على <a href="https://www.flipkart.com/pages/terms" className="text-primary-blue"> شروط الاستخدام </a> و <a href="https://www.flipkart.com/pages/privacypolicy" className="text-primary-blue"> سياسة الخصوصية </a> لـ Flipkart.</p> */}
                                    <button type="submit" className="text-white py-3 w-full bg-primary-orange shadow hover:shadow-lg rounded-sm font-medium">تسجيل الدخول</button>
                                    <Link to="/password/forgot" className="hover:bg-gray-50 text-primary-blue text-center py-3 w-full shadow border rounded-sm font-medium">هل نسيت كلمة المرور؟</Link>
                                </div>
                                {/* <!-- حاوية الزر --> */}
    
                            </div>
                        </form>
                        {/* <!-- حاوية الإدخال --> */}
    
                        <Link to="/register" className="font-medium text-sm text-primary-blue">إنشاء حساب</Link>
                    </div>
                    {/* <!-- حاوية تحرير المعلومات --> */}
    
                </div>
                {/* <!-- عمود تسجيل الدخول --> */}
    
                {/* <!-- العمود الجانبي --> */}
                {/* <div className="loginSidebar bg-primary-blue p-10 pr-12 hidden sm:flex flex-col gap-4 w-2/5">
                    <h1 className="font-medium text-white text-3xl">تسجيل الدخول</h1>
                    <p className="text-gray-200 text-lg">احصل على وصول إلى طلباتك وقائمة الرغبات والتوصيات</p>
                </div> */}
                {/* <!-- العمود الجانبي --> */}
                <br />
            </div>
            {/* <!-- صف --> */}
    
        </main>
    </>
    
    );
};

export default Login;
