import React from 'react';
import { Link } from 'react-router-dom';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useDispatch, useSelector } from 'react-redux';
import { addToWishlist, removeFromWishlist } from '../../../actions/wishlistAction';
import { useSnackbar } from 'notistack';

const Product = (props) => {
  const { _id, name, images, price } = props;

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { wishlistItems } = useSelector((state) => state.wishlist);

  const itemInWishlist = wishlistItems.some((i) => i.product === _id);

  const addToWishlistHandler = () => {
    if (itemInWishlist) {
      dispatch(removeFromWishlist(_id));
      enqueueSnackbar("Removed from Wishlist", { variant: "success" });
    } else {
      dispatch(addToWishlist(_id));
      enqueueSnackbar("Added to Wishlist", { variant: "success" });
    }
  };

  return (
    <div className="w-full p-2">
      <div className="flex flex-col items-center gap-2 px-2 py-4 bg-white border border-gray-200 rounded-lg shadow-sm h-auto md:h-[450px]">
        {/* Product Image */}
        <Link to={`/product/${_id}`} className="w-full flex-grow flex items-center justify-center overflow-hidden">
          <img
            draggable="false"
            className="w-full h-full object-cover"
            src={images[0].url}
            alt={name}
          />
        </Link>

        {/* Product Title */}
        <Link to={`/product/${_id}`} className="text-center" style={{ textDecoration: "none" }}>
          <h2 className="text-sm font-medium text-gray-800 hover:text-primary-blue transition-colors mt-2">
            {name.length > 30 ? `${name.substring(0, 30)}...` : name}
          </h2>
        </Link>

        {/* Product Price */}
        <div className="flex items-center text-sm font-medium text-gray-800">
          <span>{price.toLocaleString()} ₪</span>
        </div>

        {/* Wishlist Button */}
        <button
          onClick={addToWishlistHandler}
          className={`absolute top-2 right-2 p-1 rounded-full border ${itemInWishlist ? "text-red-500 border-red-500" : "text-gray-300 border-gray-300"} hover:text-red-500 hover:border-red-500 transition-colors`}
        >
          <FavoriteIcon sx={{ fontSize: 16 }} />
        </button>
      </div>
    </div>
  );
};

export default Product;
