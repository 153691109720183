// import mobiles from '../../assets/images/Categories/phone.png';
import bag from '../../assets/images/Categories/bag.png';
// import hoodi from '../../assets/images/Categories/hoodi.png';

import sport from '../../assets/images/Categories/sport.png';
import tsh from '../../assets/images/Categories/tsh.png';
// import travel from '../../assets/images/Categories/travel.png';
import print from '../../assets/images/Categories/print.png';
// import more from '../../assets/images/Categories/more.png';
import packagets from '../../assets/images/Categories/package.png';
import casees from '../../assets/images/Categories/case.png';
import { Link } from 'react-router-dom';

// {
//     name: "منتجات اخرى",
//     icon: more,
// },

const catNav = [

    {
        name: "شنط قماش",
        icon: bag,
    },
    {
        name: "sports",
        icon: sport,
    },
    {
        name: "بلايزنا",
        icon: tsh,
    },
    {
        name: "مطبوعاتنا",
        icon: print,
    },
    {
        name: "بكجاتنا",
        icon: packagets,
    },
    {
        name: "كفراتنا",
        icon: casees,
    },
   
];

// {
//     name: "هودياتنا",
//     icon: hoodi,
// },

const Categories = () => {
    return (
        <section className="sm:block bg-white mt-10 mb-4 min-w-full px-12 py-1 shadow overflow-hidden">
  <div className="flex flex-wrap items-center justify-between mt-4">
    {catNav.map((item, i) => (
      <Link to={`/products?category=${item.name}`} className="flex flex-col gap-1 items-center p-2 group no-underline" key={i}>
        <div className="h-16 w-16">
          <img draggable="false" className="h-full w-full object-contain" src={item.icon} alt={item.name} />
        </div>
        <span className="text-sm text-gray-800 font-medium group-hover:text-primary-blue">{item.name}</span>
      </Link>
    ))}
  </div>
</section>

    );
};

export default Categories;
