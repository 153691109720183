import { Link } from "react-router-dom";
import Loader from "../../Layouts/Loader";
import Pagination from "@mui/material/Pagination";
import { useSnackbar } from "notistack";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { clearErrors, getProducts } from "../../../actions/productAction";
import Product from './Product'

const ProductSlider = ({ title, tagline }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const location = useLocation();

  const [price, setPrice] = useState([0, 500]);
  const [category, setCategory] = useState(
    location.search ? location.search.split("=")[1] : ""
  );
  const [ratings, setRatings] = useState(0);

  // pagination
  const [currentPage, setCurrentPage] = useState(1);

  const {
    products,
    loading,
    error,
    productsCount,
    resultPerPage,
    filteredProductsCount,
  } = useSelector((state) => state.products);
  const keyword = params.keyword;

  const priceHandler = (e, newPrice) => {
    setPrice(newPrice);
  };

  const clearFilters = () => {
    setPrice([0, 500]);
    setCategory("");
    setRatings(0);
  };

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
      dispatch(clearErrors());
    }
    dispatch(getProducts(keyword, category, price, ratings, currentPage));
  }, [
    dispatch,
    keyword,
    category,
    price,
    ratings,
    currentPage,
    error,
    enqueueSnackbar,
  ]);

  return (
    <section className="bg-white w-full shadow overflow-hidden">
      {/* Header */}
      <div className="flex px-6 py-4 justify-between items-center">
        <div className="title flex flex-col gap-0.5">
          <h1 className="text-xl font-medium">{title}</h1>
          <p className="text-sm text-gray-400">{tagline}</p>
        </div>
      </div>
      <hr />
      {loading ? (
        <Loader />
      ) : (
        <div className="flex flex-col gap-2 pb-4 justify-center items-center w-full overflow-hidden bg-white">
   <div className="grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-4 p-2">
      {products.map(product => (
        <Product key={product._id} {...product} />
      ))}
    </div>          {filteredProductsCount > resultPerPage && (
            <Pagination
              count={Number(
                ((filteredProductsCount + 6) / resultPerPage).toFixed()
              )}
              page={currentPage}
              onChange={(e, val) => setCurrentPage(val)}
              color="primary"
            />
          )}
        </div>
      )}
    </section>
  );
};

export default ProductSlider;
